<template>
  <div class="login-dialog-contaner flex items-center" @click="loginVisible = true">
    <slot></slot>
  </div>
  <el-dialog v-model="loginVisible" :show-close="false" width="960" :style="{ paddingLeft: '420px', borderRadius: '13px', height: '550px' }" @close="dialogClose">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="flex justify-end">
          <i class="iconfont icon-guanbi1 cursor-pointer text-gray" @click="close"></i>
        </div>
      </div>
    </template>

    <div>
      <div class="login-container text-black">
        <div class="mb-60 flex justify-center">
          <div class="text-18 font-medium">{{ bizTypes[bizType].label }}</div>
        </div>
        <div v-if="bizType === 'login'" class="w-460 pl-80">
          <el-form ref="loginFormRef" class="login-form" label-position="top" :model="loginForm" :rules="loginRules" label-width="80px" :inline="false" size="normal">
            <el-form-item prop="email" :label="$t('邮箱地址')">
              <el-input v-model="loginForm.email"></el-input>
            </el-form-item>
            <el-form-item prop="password" :label="$t('密码')">
              <el-input v-model="loginForm.password" show-password></el-input>
            </el-form-item>

            <el-form-item size="normal">
              <div id="captcha-element" class="h-48"></div>
            </el-form-item>
            <el-form-item>
              <div class="action-btn-container w-full text-gray-light">
                <div class="mb-20 flex justify-between">
                  <div class="cursor-pointer hover:text-blue" @click="bizType = 'register'">{{ $t('前往注册') }}</div>
                  <div class="cursor-pointer hover:text-blue" @click="bizType = 'forget'">{{ $t('忘记密码') }}?</div>
                </div>
                <div class="mb-32 w-full">
                  <el-button id="login-btn" class="w-full rounded-[10px]" style="border-radius: 10px" type="primary" size="large" @click="loginAction">{{ $t('登录') }}</el-button>
                </div>
                <div class="flex justify-center">
                  <div class="flex cursor-pointer items-center hover:text-blue" @click="steamLogin">
                    <i class="iconfont icon-steam mr-5"></i><span>{{ $t('Steam登录') }}</span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="bizType === 'register'" class="w-460 pl-80">
          <el-form ref="registerFormRef" class="login-form" label-position="top" :model="registerForm" :rules="registerRules" label-width="80px" :inline="false" size="normal">
            <el-form-item prop="email" :label="$t('邮箱地址')">
              <el-input v-model="registerForm.email"></el-input>
            </el-form-item>
            <el-tooltip :content="$t('长度为8~14个字符,不允许有空格、中文')" placement="bottom" effect="dark">
              <el-form-item prop="password" :label="$t('密码')">
                <!-- content to trigger tooltip here -->
                <el-input v-model="registerForm.password" show-password></el-input>
              </el-form-item>
            </el-tooltip>

            <el-form-item size="normal">
              <div id="captcha-element"></div>
            </el-form-item>
            <el-form-item prop="captcha" :label="$t('邮箱验证码')" size="normal">
              <div class="relative w-full">
                <el-input v-model="registerForm.captcha" style="width: 100%" placeholder="" size="normal"></el-input>
                <span v-if="mailCoolDown === 61" id="sendMailBtn" class="absolute right-0 top-0 cursor-pointer text-blue underline">{{ $t('获取邮箱验证码') }}</span>
                <span v-else class="absolute right-0 top-0 cursor-pointer text-gray">{{ mailCoolDown }}s {{ $t('后可重新获取') }}</span>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="action-btn-container w-full text-gray-light">
                <div class="mb-10 flex justify-center">
                  <div class="cursor-pointer hover:text-blue" @click="bizType = 'login'">{{ $t('前往登录') }}</div>
                </div>
                <div class="mb-10 w-full">
                  <el-button id="register-btn" class="w-full rounded-[10px]" style="border-radius: 10px" type="primary" size="large" @click="registerAction">{{
                    $t('注册')
                  }}</el-button>
                </div>
                <div class="text-center text-red">
                  ps: {{ $t('如您已通过STEAM登录过SteamDT，请通过') }} <span class="cursor-pointer underline" @click="steamLogin">{{ $t('STEAM登录') }}</span>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="bizType === 'forget'" class="w-460 pl-80">
          <el-form ref="forgetFormRef" class="login-form" label-position="top" :model="forgetForm" :rules="registerRules" label-width="80px" :inline="false" size="normal">
            <el-form-item prop="email" :label="$t('邮箱地址')">
              <el-input v-model="forgetForm.email"></el-input>
            </el-form-item>

            <el-form-item size="normal">
              <div id="captcha-element"></div>
            </el-form-item>
            <el-form-item prop="captcha" :label="$t('邮箱验证码')" size="normal">
              <div class="relative w-full">
                <el-input v-model="forgetForm.captcha" style="width: 100%" placeholder="" size="normal"></el-input>
                <span v-if="mailCoolDown === 61" id="sendMailBtn" class="absolute right-0 top-0 cursor-pointer text-blue underline">{{ $t('获取邮箱验证码') }}</span>
                <span v-else class="absolute right-0 top-0 cursor-pointer text-gray">{{ mailCoolDown }}s {{ $t('后可重新获取') }}</span>
              </div>
            </el-form-item>
            <el-tooltip :content="$t('长度为8~14个字符,不允许有空格、中文')" placement="bottom" effect="dark">
              <el-form-item prop="password" :label="$t('新密码')">
                <!-- content to trigger tooltip here -->
                <el-input v-model="forgetForm.password" show-password></el-input>
              </el-form-item>
            </el-tooltip>
            <el-form-item>
              <div class="action-btn-container w-full text-gray-light">
                <div class="mb-20 flex justify-center">
                  <div class="cursor-pointer hover:text-blue" @click="bizType = 'login'">{{ $t('前往登录') }}</div>
                </div>
                <div class="mb-32 w-full">
                  <el-button id="register-btn" class="w-full rounded-[10px]" style="border-radius: 10px" type="primary" size="large" @click="forgetAction">{{
                    $t('确定')
                  }}</el-button>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="absolute bottom-0 left-0 flex h-550 w-420 flex-wrap items-end p-30 text-white" style="background: url('https://cdn.ok-skins.com/steam-stock/login/left.webp')">
        <div>
          <div class="mb-20 text-34 font-semibold">{{ $t('登录后可使用网站功能') }}</div>
          <div class="text-18 leading-[40px]">
            <div>{{ $t('饰品追踪-历史持有人') }}</div>
            <div>{{ $t('收藏(自定义大盘)') }}</div>
            <!-- <div>{{ $t('走势图展示库存饰品') }}</div>
            <div>{{ $t('全量库存数据') }}</div> -->
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { myMessage } from '#imports'
import { emailLogin, emailRegister, forgetPassword, sendEmailCode } from '@/api/login'
import { getUserInfo } from '~/api/steam'

const token = useCookie('SDT_AccessToken')
const loginVisible = ref(false)

const { t: $t } = useI18n()

const bizType = ref('login')
const mailCoolDown = ref(61)

const loginFormRef = ref(null)
const registerFormRef = ref(null)
const forgetFormRef = ref(null)

const bizTypes = {
  login: {
    label: $t('账号登录'),
    SceneId: '1233lffw'
  },
  register: {
    label: $t('账号注册'),
    SceneId: '1233lffw'
  },
  forget: {
    label: $t('密码找回'),
    SceneId: '1233lffw'
  }
}

const loginForm = ref({
  email: '',
  password: '',
  deviceOs: navigator.platform,
  captchaVerify: {}
})

const registerForm = ref({
  email: '',
  password: '',
  captcha: '',
  deviceOs: navigator.platform
})

const forgetForm = ref({
  email: '',
  password: '',
  captcha: ''
})

const loginRules = ref({
  email: [
    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
    { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 8, message: '密码长度不能小于8位', trigger: 'blur' },
    { max: 14, message: '密码长度不能大于14位', trigger: 'blur' },
    {
      validator: (rule, value, callback) => {
        if (/\s/.test(value)) {
          callback(new Error('密码不能包含空格'))
        } else if (/[\u4e00-\u9fa5]/.test(value)) {
          callback(new Error('密码不能包含中文字符'))
        } else {
          callback()
        }
      },
      trigger: 'blur'
    }
  ]
})

const registerRules = ref({
  email: [
    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
    { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
  ],
  password: [
    { required: true, message: bizType === 'register' ? '请输入密码' : '请输入新密码', trigger: 'blur' },
    { min: 8, message: '密码长度不能小于8位', trigger: 'blur' },
    { max: 14, message: '密码长度不能大于14位', trigger: 'blur' },
    {
      validator: (rule, value, callback) => {
        if (/\s/.test(value)) {
          callback(new Error('密码不能包含空格'))
        } else if (/[\u4e00-\u9fa5]/.test(value)) {
          callback(new Error('密码不能包含中文字符'))
        } else {
          callback()
        }
      },
      trigger: 'blur'
    }
  ],
  captcha: [
    { required: true, message: '请输入邮箱验证码', trigger: 'blur' },
    {
      validator: (rule, value, callback) => {
        if (value.length !== 6) {
          callback(new Error('请输入6位验证码'))
        } else {
          callback()
        }
      },
      trigger: 'blur'
    }
  ]
})

watch(loginVisible, val => {
  if (val) {
    setTimeout(() => {
      initCapacha()
    }, 100)
    // initCapacha()
  }
})

watch(bizType, val => {
  setTimeout(() => {
    initCapacha()
  }, 100)
  // initCapacha()
})

const store = useNuxtStore()
watch(
  () => store.loginDialog,
  val => {
    if (val) {
      console.log('val', val)
      loginVisible.value = val
    }
  }
)

watch()

onMounted(() => {
  // initCapacha()
})

const loginAction = () => {
  loginFormRef.value.validate(valid => {
    console.log('valid', valid)
    if (valid) {
      console.log('loginForm', loginForm)
    }
  })
}

const registerAction = () => {
  registerFormRef.value.validate(valid => {
    console.log('valid', valid)
    if (valid) {
      console.log('registerForm', registerForm)
      emailRegister(registerForm).then(res => {
        let token = useCookie('SDT_AccessToken', {
          maxAge: res.data?.tokenTimeout
        })
        token.value = res.data.accessToken
        const store = useNuxtStore()
        store.setUserInfo(res.data.user)
        myMessage({
          type: 'success',
          message: '注册成功',
          duration: 3000,
          onClose: () => {
            location.reload()
          }
        })
      })
    }
  })
}

const forgetAction = () => {
  forgetFormRef.value.validate(valid => {
    console.log('valid', valid)
    if (valid) {
      forgetPassword(forgetForm).then(res => {
        token.value = res.data.accessToken
        const store = useNuxtStore()
        store.setUserInfo(res.data.user)
        myMessage({
          type: 'success',
          message: '密码找回成功，请使用新密码登录',
          duration: 3000,
          onClose: () => {
            // location.reload()
            bizType.value = 'login'
          }
        })
      })
    }
  })
}

const initCapacha = () => {
  initAliyunCaptcha({
    SceneId: '1233lffw', // 场景ID。通过步骤一添加验证场景后，您可以在验证码场景列表，获取该场景的场景ID
    prefix: 'd9kzqx', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
    mode: bizType.value === 'login' ? 'embed' : 'popup', // 验证码模式。embed表示要集成的验证码模式为嵌入式。无需修改
    element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
    button: bizType.value === 'login' ? '#login-btn' : '#sendMailBtn', // 触发业务请求的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
    captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
    onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
    getInstance: getInstance, // 绑定验证码实例函数，无需修改
    slideStyle: {
      width: 380,
      height: 40
    }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
    language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    immediate: false, // 完成验证后，是否立即发送验证请求（调用captchaVerifyCallback函数）
    region: 'cn' //验证码示例所属地区，支持中国内地（cn）、新加坡（sgp）
  })
}

const captchaVerifyCallback = async result => {
  if (bizType.value === 'login') {
    console.log('登录验证成功', result)
    loginForm.value.captchaVerify = result
    emailLogin(loginForm.value).then(res => {
      let token = useCookie('SDT_AccessToken', {
        maxAge: res.data?.tokenTimeout
      })
      token.value = res.data.accessToken
      myMessage({
        type: 'success',
        message: '登录成功',
        duration: 3000,
        onClose: () => {
          location.reload()
        }
      })
    })
    return {
      captchaResult: true // 验证码验证是否通过，boolean类型，必选
    }
  }
  if (bizType.value === 'register') {
    console.log('注册验证成功', result)
    const validateRes = await registerFormRef.value.validateField('email')
    console.log('validateRes', validateRes)
    const verifyResult = {
      captchaResult: true // 验证码验证是否通过，boolean类型，必选
    }
    if (validateRes) {
      const apiResult = await sendEmailCode({ email: registerForm.value.email, templateId: 1, captchaVerify: result })
      mailCountDownTimer()
    }
    return verifyResult
  }
  if (bizType.value === 'forget') {
    const validateRes = await forgetFormRef.value.validateField('email')
    if (validateRes) {
      const apiResult = await sendEmailCode({ email: forgetForm.value.email, templateId: 2, captchaVerify: result })
      mailCountDownTimer()
      const verifyResult = {
        captchaResult: apiResult.data, // 验证码验证是否通过，boolean类型，必选
        bizResult: apiResult.data // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
      }
      return verifyResult
    }
  }
}
const onBizResultCallback = result => {
  console.log('业务请求结果', result)
}
const getInstance = instance => {
  console.log('bizType', bizType.value)
  console.log('获取验证码实例', instance)
}

const mailCountDownTimer = () => {
  if (mailCoolDown.value > 0) {
    mailCoolDown.value--
    setTimeout(mailCountDownTimer, 1000)
  } else {
    mailCoolDown.value = 61
    nextTick(() => {
      initCapacha()
    })
  }
}

function steamLogin() {
  window.localStorage.removeItem('steamloginStatus')
  ElMessageBox.alert($t('如无法正常访问，建议使用加速器对STEAM进行加速！'), $t('访问遇到问题？'), {
    confirmButtonText: $t('我知道了')
  })
    .then(() => {})
    .catch(() => {})
  const location = window.location
  const steamLoginUrl = `https://steamcommunity.com/openid/login?openid.mode=checkid_setup&openid.ns=http://specs.openid.net/auth/2.0&openid.realm=${location.origin}&openid.sreg.required=nickname,email,fullname&openid.assoc_handle=None&openid.return_to=${location.origin + '/login/steam'}&openid.ns.sreg=http://openid.net/extensions/sreg/1.1&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select`
  window.open(steamLoginUrl, '_blank', 'popup,width=500,height=600')
  window.addEventListener('storage', async function (e) {
    if (e.key == 'steamloginStatus') {
      console.log('storageValue', e.newValue, typeof e.newValue)
      if (e.newValue && e.newValue === 'true') {
        window.localStorage.removeItem('steamloginStatus')
        const bindEmailTips = useCookie('SDT_BindEmailTips')
        const { data: tempuser } = await getUserInfo()
        if (!tempuser.email) {
          bindEmailTips.value = 1
        }
        myMessage({
          type: 'success',
          message: 'Steam登录成功',
          duration: 2000,
          onClose: () => {
            location.reload()
          }
        })
      } else {
        window.localStorage.removeItem('steamloginStatus')
        myMessage({
          type: 'error',
          message: 'Steam登录失败，请重试',
          duration: 3000
        })
      }
    }
  })
}

const dialogClose = () => {
  loginVisible.value = false
  store.setLoginDialog(false)
}
</script>

<style lang="scss">
.login-form {
  .el-input__wrapper {
    border-radius: 0;
    box-shadow: inset 0 -1px 0 0 var(--el-input-border-color);
  }
  .el-form-item {
    &.is-error {
      .el-input__wrapper {
        box-shadow: inset 0 -1px 0 0 var(--el-color-danger) !important;
      }
    }
    &.is-focus {
      .el-input__wrapper {
        box-shadow: inset 0 -1px 0 0 var(--el-input-focus-border) !important;
      }
    }
  }
}
</style>
